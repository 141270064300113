import React from 'react';
import styles from './../../css/BlogContent.module.css';
import image01 from "./../../img/20241009_zatvaranje_d_vedran_radovanovic_06.jpg";
import image02 from "./../../img/20241009_zatvaranje_d_vedran_radovanovic_09.jpg";
import image03 from "./../../img/20241008_drone_days_predavanje_d_andro_anic_milic_02.jpg";
import image04 from "./../../img/bronse sponzors.png"
import image05 from "./../../img/droneDays.png"

function Fall({
                         title = "Malotko razmišlja o tome da dronovi padaju\n",
                         date = "Veljača, 2025",
                         mainImage = image03,
                         content = {
                             sections: [
                                 {
                                     title: "",
                                     content: "Nakon što je prošao prvi val društvene fascinacije dronovima, u Hrvatskoj se razvila civilna infrastruktura koja omogućuje osposobljavanje za udaljenog pilota besposadne letjelice, ali i učenje letenja. Jako važno za sve koji ne žele da dron kojim upravljaju donese nesreću nekom drugom! Uz obvezu prijave letova razvile su se, napokon, i usluge osiguranja dronova, koje su prije osiguravatelji nerado pružali\n" +
                                         "Nekima su zabava, drugima izvor prihoda, a nekim trećima – nevolja. No, kao i za sva druga vozila, potrebno je naučiti upravljati njima i dobro se upoznati sa svim propisima i pravilima, kao i specifičnostima stroja. Nije riječ o vozilima, već o dronovima, letećim strojevima koji su jedno vrijeme bili jako popularni u Hrvatskoj. Nakon što je prošao prvi val društvene fascinacije novostima koje su te simpatične i korisne napravice donijele, u Hrvatskoj se razvila civilna infrastruktura koja omogućuje osposobljavanje za udaljenog pilota besposadne letjelice, ali i učenje letenja. Jako važno za sve koji ne žele da dron kojim upravljaju donese nesreću nekom drugom!",
                                     image: image01
                                 },
                                 {
                                     title: "Izgubljeni u letu",
                                     content: "Još je važnije što su se, uz obvezu, razvile usluge osiguranja dronova, koje su prije osiguravatelji nerado pružali.\n" +
                                         "\n" +
                                         "– Kazne su velike, mnogi su ih i dobili, samo ne žele javno govoriti o njima. Poznato mi je da je jedan registriran operater i pilot s položenim ispitom bio kažnjen s deset tisuća kuna jer je uhvaćen u prekršaju zbog neprijavljivanja leta. Čovjek, jednostavno, nije mislio da će ga itko išta pitati, no naišla je policija, pitala ga je li prijavio let, sve zapisala, prijavila – i došla mu je kazna. Za policu osiguranja, odnosno za upravljanje bez osiguranja, kazne su od 2600 eura, a mogu biti do više od 26 tisuća eura – ispričao nam je osiguravatelj dronova Hrvoje Sremić.\n" +
                                         "\n" +
                                         "Što se kazni tiče, osnivač startupa AIR-RMLD Ivan Vidaković upozorio nas je da se kazne propisuju usporedno – i vlasniku i pilotu drona. Iz vlastitog iskustva na jednome sportskom događaju u Slavoniji kazna nije bila izrečena samo njemu kao pilotu već i produkcijskoj kući. U prekršajnom postupku oslobođen je odgovornosti, no kazni očito ima. A ima i šteta jer je dron letjelica kao i svaka druga, samo manja. Može pasti i uništiti se ili se izgubiti; tada je njegov vlasnik na gubitku. Ali mnogo je lošije kad našteti nekom drugom.\n" +
                                         "\n" +
                                         "– Nitko ne razmišlja da dron pada, a doista pada, nevezano uz spremnost pilota. Past će zbog sudara s pticom, Windowsi će zaštekati, izgubit će se, fly away nije toliko rijedak – rekao nam je Vidaković.\n" +
                                         "\n",
                                     image: image02
                                 },
                                 {
                                     title: "Škole upravljanja",
                                     content: "Tu dvojicu entuzijasta u upravljanju dronovima zamolili smo da dignu svoje strojeve i demonstriraju nam na što treba paziti pri upravljanju i kako izgleda jedan let. Na parkiralištu Zagrebačkog velesajma podignuli su svoje dronove i pokazali nam što mogu. Sremić je podignuo hobistički dron kojim se koristi povremeno, a Vidaković je donio letjelicu svoje tvrtke kojom pregledava Plinacrove plinovode u potrazi za mjestima na kojima curi plin.Kao privatna tvrtka provodimo samo specijalizirane obuke. Recimo, poduzeće nas nazove i traži da šest njegovih geodeta naučimo upravljati dronovima. To nije zakonom propisana obuka, ali ako netko želi naučiti upravljati letjelicom, pomoći ćemo mu. Kada držimo školicu, učimo ljude upravljati bez GPS-a, jer jednom kad vam se dogodi da ostanete bez GPS-a, a naučili ste letjeti samo s pomoću njega, onda ste ‘u banani‘. Kad naučite, tu je ‘mišićna memorija‘ pa se na to možete osloniti u nepredviđenoj situaciji – ispričao nam je Vidaković."
                                 },
                                 {
                                     title: "Bolje bez GPS-a",
                                     content: "Prisjetio se i kako je Sremić kod njega učio letjeti. Dobio bi zadatak da ​dronom ocrtava kvadrat u zraku pa krug, zatim bi to ponavljao i ponavljao.Da, učio si me i rotiranje, tako je. Repeticije, repeticije, sve dok ne dobiješ osjećaj. Dronovi su povezani na satelite koji drže stroj u mjestu kad pilot drona pusti kontrole, no kad nema GPS-a, onda dron nastavlja letjeti. Ako pilot pritom ne kontrolira upravljač, letjet će u smjeru koji mu je posljednji određen. Zahvaljujući GPS-u upravljanje je jednostavno i lagano za početnike. Ali vidjeli ste, kad smo došli i pokrenuli dron, nije mi se uspio povezati s GPS-om, što sam odmah uočio i spustio ga pa restartao. Bez GPS-a veoma je teško upravljati njime – rekao nam je Hrvoje Sremić.Procedura koja je pri letu dronovima obvezna i podložna kaznama ako se ne ispuni nije komplicirana. Prije polijetanja potrebno je let prijaviti Agenciji za civilno zrakoplovstvo i rezervirati vrijeme za to. Riječ je o praktičnoj proceduri koja služi tomu da piloti budu informirani tko još leti na području na kojem će oni letjeti. Odobrenje se dobije vrlo brzo jer su dronovi verificirani s pomoću aplikacije: s jedne strane od svojih operatera, a s druge od aplikacije. Nota bene, to se ne može obaviti bez osiguranja. Zatim slijedi letenje.Recimo, da nas trojica radimo Hanžekovićev memorijal, jedan za HRT, drugi za RTL, a treći freelancea. Znači, to su tri službena drona u zraku. Sva trojica rezerviramo se lako na istoj lokaciji, praktično smo na zemljovidu jedan na drugom. To nam služi da budemo svjesni da su tu još dva drona i da moramo obratiti pozornost na njih – rekao nam je Vidaković.",
                                     image: image04
                                 },
                                 {
                                     title: "Što se smije, što se mora",
                                     content: "Kako je objasnio Sremić, za ljude koji žele letjeti samo hobistički registriranje drona je opcija. Obveza je registracija operatera neovisno o načinu korištenja drona – sport i hobi ili radovi iz zraka – a osiguranje je obvezno ako se dron upotrebljava za rad iz zraka. Pritom se već i fotografiranje i snimanje za privatne svrhe smatraju radom. Obveze polaganja ispita za udaljenog pilota nema za dronove do 250 grama, no nedostatak registracije i osposobljavanja znači da piloti ni u jednom trenutku ne smiju obaviti operaciju dronom. Ne smiju ni stisnuti tipku record na upravljaču, odnosno ne smiju snimati jer je to već letačka operacija. Bilo kakvi radovi iz zraka letačka su operacija i traže registraciju i obuku pilota. Letačka operacija može se izvoditi dronom do 250 grama, za koji nije potrebno polagati ispit, ali operater se mora registrirati i u tom slučaju ugovori policu osiguranja.\n" +
                                         "\n" +
                                         "– Kao osoba koja se ovime bavi iz hobija mislim da bi svatko tko leti dronom iz bilo kojih pobuda trebao proći osposobljavanje Agencije za civilno zrakoplovstvo i školu letenja. Uvijek je savjet da se ispit položi. Polaganje ispita za dio je kategorija online, osposobljavanje je također online i besplatno. Prije polaganja sustav vas u sklopu edukacije upoznaje s pravilima letenja. To sam i ja napravio: položio sam ispit, registrirao se kao operater, ali nisam imao pojma o letenju. Pa sam onda nazvao Ivana da mi pokaže. Većina ljudi zainteresira se za dronove na internetu, kupi ih i onda se tek raspituje što bi trebali napraviti – rekao nam je Sremić.",
                                     image: image05
                                 },
                                 {
                                     title: "Zabrane nakon nesreće",
                                     content: "Naravno da je taj pilot koji se bavi osiguranjem dronova upozorio da bi svatko i trebao osigurati dron. Premda ima osoba kojima to ne pada na pamet, korisno je prisjetiti se da i iskusni piloti mogu napraviti pogreške koje lako postanu kobne. Možda najpoznatija dogodila se 2015. kad je dron za snimanje pao na skijašku stazu tijekom natjecanja Svjetskoga kupa u Madoni di Campigliu i zamalo pogodio Marcela Hirschera. Srećom, poznati skijaš nije bio ozlijeđen, ali posljedice su mogle biti teške jer je posrijedi bio stroj težak deset kilograma koji je pao s deset do dvadeset metara. Nakon toga je FIS zabranio dronove. Navodilo se da je uzrok te nesreće bio kvar na dronu i pritom se nisu poštovala FIS-ova pravila o sigurnosnoj udaljenosti za letjelice tijekom utrke.Naravno, kao i drugim vozilima, dronovima je moguće upravljati pod utjecajem alkohola, lijekova i droga, što je, naravno, također zabranjeno. Europski propisi uvedeni u naš zakonodavni korpus, naime, jasno nalažu da nije dopušteno upravljati dronom pod utjecajem alkohola. Kako navode naša dvojica sugovornika, policija vam može ponuditi da pušete u dreger ako vas zatekne u udaljenom pilotiranju. Iako je taj propis samorazumljiv, pa alkoholizirani piloti dronova podliježu kazni, neki drugi koji reguliraju problematiku dronova ​mogli bi se popraviti.",
                                     image: image05
                                 },
                                 {
                                     title: "Prostor za popravak pravila",
                                     content: "Naime, domaća pravna rješenja priječe snimanje svima, pa i registriranim operaterima s položenim ispitom, ako nemaju tvrtku s opisanom djelatnošću.Registrirani sam operater s položenim ispitom, ali to ne mogu napraviti ako nemam djelatnost navedenu u poduzeću. Već i ako letite zrakoplovom i snimite svoj rodni kraj u letu, kršite Zakon o obrani. Hrvatska udruga bespilotnih sustava (HUBS) traži da se Zakon o obrani pri idućim izmjenama popravi i da se tih nekoliko članaka makne iz zakona – rekli su naši sugovornici.Logika Zakona o obrani jasna je: veća kontrola snimanja iz zraka radi zaštite vojnih i sigurnosnih lokacija. No jasna je i HUBS-ova logika, koji tu zabranu smatra suvišnom zbog mogućnosti koje pruža satelitska tehnologija.Dronovi u Hrvatskoj postali su, eto, toliko rasprostranjeni da se ni vojna zabrana snimanja iz zraka neće poštovati ako stižu nacionalni pobjednici sa svjetskih i europskih sportskih natjecanja. Isto tako udruga dronaša ili drondžija, kako sami sebe vole nazvati, sve je utjecajnija, pa osim što lobira za zakonske promjene, ove će godine u lipnju u Zadru ugostiti opću skupštinu JEDA-e, zajedničke europske dronske udruge koja okuplja udruge iz zemalja članica Europske unije. Na općoj skupštini u Zadru, najavili su naši sugovornici, okupit će se njihov cijeli europski fah.Jednako je s letačkim operacijama koje se s pomoću njih mogu obaviti. Vidakovićev AIR-RMLD, čiji je dron podignuo u zrak da nam demonstrira mogućnosti te vrste letenja, posluje i s Hrvatskom poštom i s Plinacrom, a i s Hrvatskim cestama.",
                                     image: image05
                                 },
                                 {
                                     title: "Detektiranje prijetnji",
                                     content: "\n" +
                                         "Što li snima za njih? Nije valjda da nadzire jesu li im zaposlenici na radnome mjestu?\n" +
                                         "\n" +
                                         "– To su naši klijenti među javnim tvrtkama. Imamo ih, naravno, i u privatnom sektoru. Za Poštu smo radili i još radimo prvu dostavu dronom. Za Plinacro smo razvili laser za nadzor i otkrivanje mjesta curenja plina na plinovodima nedostupnima ljudskom vidu. Napravili smo dokaz koncepta, pokazao se dobrim i, evo, već pet godina pregledavamo plinovode u gudurama, kanalima, uz prometnice. Načelo je da laserom utvrđujemo koncentraciju metana u zraku koja u određenim razmjerima pokazuje curenje plina. U Plinacru dosad nismo zabilježili curenja, ali jesmo u nekim manjim plinarama u Hrvatskoj i Njemačkoj. Za Hrvatske ceste već tri godine obavljamo preventivne preglede vijadukta i mostova, ortofoto snimamo most, kompletno centimetar po centimetar, a zatim i lidarom – ispričao nam je Vidaković.\n" +
                                         "\n" +
                                         "Ta rješenja AIR-RMLD nije patentirao, ali i ne treba jer je razvio vlastiti softver koji te podatke spaja s kartografskima, što onda može prebaciti korisnicima u njihov računalni sustav da sami mogu pregledati nalaze. Dakle, mogućnosti postoje, ali prije polijetanja svakako je korisno naučiti pravila, školovati se u letenju i, naravno, osigurati letjelicu. A ako ima ideja, onda može biti i komercijalne upotrebe. ",
                                     image: image05
                                 },
                             ]
                         }
                     }) {
    return (
        <main className={styles.content}>
            <div className={styles.mainContent}>
                <article className={styles.blogArticle}>
                    <header className={styles.blogInfo}>
                        <h1 className={styles.heading}>{title}</h1>
                        <time className={styles.shortInfo}>{date}</time>
                    </header>
                    <img
                        src={mainImage}
                        alt="Dron u letu"
                        className={styles.mainImage}
                        loading="lazy"
                    />
                    <p className={styles.introduction}>
                        {content.introduction}
                    </p>
                    {content.sections.map((section, index) => (
                        <section key={index} className={styles.section}>
                            <h2 className={styles.sectionTitle}>{section.title}</h2>
                            {section.content.startsWith("http") ? (
                                <a
                                    href={section.content}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className={styles.sectionLink}
                                >
                                    https://dronedays.fer.hr/en/homepage-2024-2/
                                </a>
                            ) : (
                                <p className={styles.sectionContent}>{section.content}</p>
                            )}
                            {section.image && (
                                <img
                                    src={section.image}
                                    alt={`Ilustracija za sekciju ${section.title}`}
                                    className={styles.sectionImage}
                                    loading="lazy"
                                />
                            )}
                        </section>
                    ))}
                </article>
            </div>
        </main>
    );
}

export default Fall;
