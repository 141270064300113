import Index from "./Components/Index";
import {Routes} from "react-router-dom";
import {Route} from "react-router-dom";
import NavBar from "./Components/Navbar/NavBar";
import MainPage from "./Components/MainPage/MainPage";
import InsuranceOffers from "./Components/Offer/InsuranceOffers";
import Registration from "./Components/Registration/Registration";
import InsuranceMainPage from "./Components/Insurance/InsuranceMainPage";
import KaskoMainPage from "./Components/Kasko/KaskoMainPage";
import CategoriesMainPage from "./Components/Categories/CategoriesMainPage";
import TrainingMainPage from "./Components/Training/TrainingMainPage";
import LawMainPage from "./Components/Law/LawMainPage";
import HistoryPage from "./Components/History/HistoryPage";
import FAQPage from "./Components/FAQ/FAQPage";
import OtherInsurancesMain from "./Components/OtherInsurrances/OtherInsurancesMain";
import DroneInsurance from "./Components/MainPage/MainPage";
import DronInsuranceMainPage from "./Components/DronInsurance/DronInsuranceMainPage";
import Form from "./Components/Form";
import NewsPage from "./Components/Blog/NewsPage";
import EnglishMainPage from "./Components/English/EnglishMainPage";
import ContactPage from "./Components/ContactPage/ContactPage";
import BlogLayout from "./Components/Blog/BlogLayout";
import Fall from "./Components/Blog/fall";

function App() {
    return (
        <div className="App">
            <Routes>
                <Route exact path="/" element={<MainPage/>}/>
                <Route exact path="/registration" element={<Registration/>}/>
                <Route exact path="/insurance" element={<InsuranceMainPage/>}/>
                <Route exact path="/kasko" element={<KaskoMainPage/>}/>
                <Route exact path="/categories" element={<CategoriesMainPage/>}/>
                <Route exact path="/training" element={<TrainingMainPage/>}/>
                <Route exact path="/law" element={<LawMainPage/>}/>
                <Route exact path="/history" element={<HistoryPage/>}/>
                <Route exact path="/faq" element={<FAQPage/>}/>
                <Route exact path="/otherInsurances" element={<OtherInsurancesMain/>}/>
                <Route exact path="/dronInsurance" element={<DronInsuranceMainPage/>}/>
                <Route exact path="/form" element={<Form/>}/>
                <Route exact path="/blog" element={<NewsPage/>}/>
                <Route exact path="/eng" element={<EnglishMainPage/>}/>
                <Route exact path="/contact" element={<ContactPage/>}/>
                <Route exact path="/droneDaysArticle" element={<BlogLayout/>}/>
                <Route exact path="/droneFallArticle" element={<Fall/>}/>
            </Routes>
        </div>
    );
}

export default App;
