import React from 'react';
import PostCard from './PostCard';
import styles from './../../css/NewsPage.module.css';
import { Link } from "react-router-dom";

const posts = [
  {
    image: "https://cdn.builder.io/api/v1/image/assets/TEMP/51f84396769c146d3276587d74a97d505f30f4ebe2b457f657d9fe724afcc801?placeholderIfAbsent=true&apiKey=7ecb829facc1490fb94a0c8f410b93bc",
    title: "Malotko razmišlja o tome da dronovi padaju",
    date: "Veljača, 2025",
    url: "/droneFallArticle"
  }
];

const PostGrid = () => {
  return (
    <div className={styles.postGrid}>
      {posts.map((post, index) => (
        <Link to={post.url} key={index} className={styles.postLink}>
          <PostCard {...post} />
        </Link>
      ))}
    </div>
  );
};

export default PostGrid;
