import React, {useState, useEffect} from 'react';
import styles from './../../css/MainPage.module.css';
import {Link, useNavigate} from 'react-router-dom';
import HeroDronInsurance from './../../img/DronInsuranceHero.png';
import HeroRegistration from './../../img/HeroRegistration.png';
import HeroPilot from './../../img/HeroPilot.png';
import HeroCategories from './../../img/HeroCategories.png';
import HeroTraining from './../../img/HeroTraining.png';
import NavBar from "../Navbar/NavBar";
import InsuranceCardList from "./InsuranceCardList";
import AboutUs from "./AboutUs";
import Footer from "../Footer";
import MoreIcon from "./../../img/MoreIcon.png";
import CalculationIcon from "./../../img/Diagonal  Right Up Arrow.png";
import law from "./../../img/zr.png"
import registrationMobile from "./../../img/registration mobile.png"
import heroMobile from "./../../img/hero mobile.png"
import categoriesMobile from "./../../img/category mobile.png"
import pilotMobile from "./../../img/pilot mobile.png"
import trainingMobile from "./../../img/law mobile.png"
import hero from "./../../img/alessio-soggetti-rSFxBGpnluw-unsplash.jpg"
import Testimonials from "./Testimonials";

function MainPage() {
    const slides = [
        {
            title: 'Osiguranje drona',
            description: 'Ugovorite online policu obveznog osiguranja za dron.\nDron osiguranje na dohvat ruke, kasko ili osiguranje od odgovornosti operatora bespilotnog zrakoplova.',
            imageDesktop: hero,
            imageMobile: heroMobile,
            learnMoreLink: '/dronInsurance',
        },
        {
            title: 'Registracija operatora bespilotnog zrakoplova',
            description: 'Osiguranje drona, registracija operatora, osposobljavanje udaljenog pilota i prijava leta procedura je koju je potrebno odraditi za legalno letenje.',
            imageDesktop: HeroRegistration,
            imageMobile: registrationMobile,
            learnMoreLink: '/registration',
        },
        {
            title: 'Osposobljavanje udaljenog pilota',
            description: 'Polaganje ispita za udaljene pilote provodi se neovisno o registraciji operatora (pilot ne mora posjedovati dron) i obvezan je za sve koji misle upravljati s dronom čiji je MTOM veći od 250 g.',
            imageDesktop: HeroPilot,
            imageMobile: pilotMobile,
            learnMoreLink: '/training',
        },
        {
            title: 'Kategorije letačkih operacija',
            description: 'Otvorena, posebna i certificirana kategorija',
            imageDesktop: HeroCategories,
            imageMobile: categoriesMobile,
            learnMoreLink: '/categories',
        },
        {
            title: 'Zakonska regulativa',
            description: 'Koje radnje je potrebno poduzeti prije leta. Operator ili udaljeni pilot? Uredbe i propisi.',
            imageDesktop: HeroTraining,
            imageMobile: trainingMobile,
            learnMoreLink: '/law',
        },
    ];


    const [currentSlide, setCurrentSlide] = useState(0);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // Novi state za praćenje širine ekrana
    const [fadeClass, setFadeClass] = useState(styles.fadeIn);
    const [manualChange, setManualChange] = useState(false);
    const [progressBarWidth, setProgressBarWidth] = useState(0);
    const navigate = useNavigate();

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768); // Ažurira stanje kada je širina ekrana manja ili jednaka 768px
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        const intervalTime = 5000;
        const progressTime = 50;

        const startAutoSlide = () => {
            return setInterval(() => {
                setFadeClass(styles.fadeOut);

                setTimeout(() => {
                    setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
                    setFadeClass(styles.fadeIn);
                    setProgressBarWidth(0);
                }, 500);
            }, intervalTime);
        };

        let interval = startAutoSlide();

        const progressInterval = setInterval(() => {
            setProgressBarWidth((prev) => {
                if (prev < 100) {
                    return prev + (100 / (intervalTime / progressTime));
                } else {
                    return 100;
                }
            });
        }, progressTime);

        if (manualChange) {
            clearInterval(interval);
            clearInterval(progressInterval);
            interval = startAutoSlide();
            setManualChange(false);
        }

        return () => {
            clearInterval(interval);
            clearInterval(progressInterval);
        };
    }, [slides.length, currentSlide, manualChange]);

    const handleSlideChange = (index) => {
        setCurrentSlide(index);
        setFadeClass(styles.fadeIn);
        setProgressBarWidth(0);
        setManualChange(true);
    };

    const handleLearnMoreClick = () => {
        navigate(slides[currentSlide].learnMoreLink);
    };

    return (
        <div className={styles.mainContainer}>
            <NavBar/>
            <div className="elfsight-app-a1aff5b5-c465-4574-a672-11812d116730" data-elfsight-app-lazy></div>
            <div className={styles.pageContainer}>
                <div className={styles.leftColumn}>
                    <h1 style={{height: "100px"}}>{slides[currentSlide].title}</h1>
                    <p style={{height: "50px"}}>{slides[currentSlide].description}</p>

                    <div className={styles.buttonRow}>
                        <Link to={"/form"}>
                            <button className={styles.calcButton}>Izračun <img src={CalculationIcon} alt=""/></button>
                        </Link>
                        <button className={styles.learnMoreButton} onClick={handleLearnMoreClick}>
                            Saznajte više <img src={MoreIcon} alt=""/>
                        </button>
                    </div>

                    <div className={styles.buttonContainer}>
                        {slides.map((slide, index) => (
                            <button
                                key={index}
                                className={`${styles.button} ${currentSlide === index ? styles.activeButton : ''}`}
                                onClick={() => handleSlideChange(index)}
                            >
                                <img src={slide.imageDesktop} alt={`Button image ${index + 1}`}/>
                            </button>
                        ))}
                    </div>
                </div>

                <div className={styles.rightColumn}>
                    <img
                        src={isMobile ? slides[currentSlide].imageMobile : slides[currentSlide].imageDesktop}
                        alt={`Slide image ${currentSlide + 1}`}
                        className={`${styles.mainImage} ${fadeClass}`}
                    />
                    <div className={styles.progressBarWrapper}>
                        <div className={styles.progressBar} style={{width: `${progressBarWidth}%`}}></div>
                    </div>
                </div>
            </div>
            <InsuranceCardList/>
            <AboutUs/>
            <Testimonials/>
            <Footer/>
        </div>
    );
}

export default MainPage;
