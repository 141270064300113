import React from "react";
import {Swiper, SwiperSlide} from "swiper/react";
import {Navigation, Pagination, Autoplay} from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import styles from "./../../css/Testimonials.module.css";
import logo from "./../../img/FER_logo_1.png";
import uniZg from "../../img/UniZg.png";
import facultyRijeka from "../../img/dalekovod-projekt logo.png";
import facultyOsijek from "../../img/Aviteh_CMYK [Converted].png";

const testimonials = [
    {
        text: "Zadovoljstvo nam je surađivati s Hrvojem Sremićem i timom društva Semel pro semper d.o.o. Višegodišnja uspješna suradnja, ažurnost, profesionalizam i pouzdanost.",
        address: "SVEUČILIŠTE U ZAGREBU\nFAKULTET ELEKTROTEHNIKE I RAČUNARSTVA\nUNSKA 3, 10 000 ZAGREB",
        images: [logo, uniZg]
    },

    {
        text: "Hrvoje Sremić je naš dugogodišnji i pouzdani poslovni partner na kojeg se uvijek možemo osloniti i kojeg s povjerenjem preporučujemo svojim klijentima! Svojom stručnošću, iskustvom i visokom profesionalnošću pokazao se kao mjesto broj 1 za osiguranje drona!",
        address: "AVITEH Audio Video Tehnologije d.o.o.",
        images: [facultyOsijek]
    },
    {
        text: "Korištenje bespilotnih zrakoplova uvelike pomaže u svakodnevnom radu, iznova donosi nove mogućnosti, ali i rizike koje pokrivamo kupnjom polica osiguranja od odgovornosti operatora bespilotnog zrakoplova i kasko osiguranja bespilotnih zrakoplova uz savjete i asistenciju g. Hrvoja Sremića.",
        address: "Dalekovod - Projekt d.o.o. za projektiranje, nadzor, konzalting i inženjering",
        images: [facultyRijeka]
    },
];

export default function Testimonials() {
    return (
        <div className={styles.testimonialsWrapper}>
            <Swiper
                spaceBetween={50}
                slidesPerView={1}
                pagination={{clickable: true}}
                navigation={true}
                autoplay={{delay: 5000, disableOnInteraction: false}}
                modules={[Navigation, Pagination, Autoplay]}
            >
                {testimonials.map((testimonial, index) => (
                    <SwiperSlide key={index}>
                        <div className={styles.testimonialsMain} style={{
                            minHeight: "300px",
                            height: "450px",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                            paddingLeft: "100px",
                            paddingRight: "100px"
                        }}>
                            <div className={styles.images} style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                minHeight: "100px"
                            }}>
                                {testimonial.images.map((image, i) => {
                                    const isSmallImage = image === logo || image === uniZg; // Provjera za FER i UniZg
                                    return (
                                        <img
                                            key={i}
                                            src={image}
                                            alt={`Testimonial ${index + 1} Image ${i + 1}`}
                                            style={{
                                                width: isSmallImage ? "150px" : "200px",
                                                height: "auto",
                                                margin: testimonial.images.length === 1 ? "0 auto" : "0px 10px"
                                            }}
                                        />
                                    );
                                })}
                            </div>

                            <p>“{testimonial.text}”</p>
                            <div className={styles.address}>
                                {testimonial.address.split("\n").map((line, i) => (
                                    <p key={i}>{line}</p>
                                ))}
                            </div>
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    );
}
